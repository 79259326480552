<template>
    <v-container grid-list-xl fluid>
        <!-- <Notifier /> -->
        <BoardsError />
        <Charger />
    </v-container>
</template>

<script>
import Charger from "./Charger";
// import Notifier from "./Notifier";
import BoardsError from "./BoardsError";

export default {
    components: { Charger, BoardsError },
}
</script>
